import * as commonStyles from '../../styles/scss/components/NewWizard/WizardCommon.module.scss';
import { cdnLoader } from '../../config/cdnImageLoader.config';

const PlayersNumberStep = (props) => {
  const {
    playersNumber,
    handleChoosePlayerNumber,
    selectedPlayerNumber,
    currentStep,
    currentlySearchingPLayersNumbers
  } = props
  return (
    <div className = {`${commonStyles.buttons_container} ${commonStyles.players_container}`}>
      <div className = {commonStyles.inner_container}>
        <div 
          className = {`${commonStyles.button_wrapper}`} 
          key = {'playerNumberany'}
          onClick = {() => {handleChoosePlayerNumber(0, currentStep + 1)}}
          >
          <div className  = {`${commonStyles.button_container} ${commonStyles.players_number} ${selectedPlayerNumber === 0 ? commonStyles.active : ''}`}>
            <div className = {`${commonStyles.standart_background} ${commonStyles.container}`}></div>
            <div className = {`${commonStyles.hover_background} ${commonStyles.container}`}></div>
            <div className = {`${commonStyles.active_background} ${commonStyles.container}`}></div>
            <div className = {`${commonStyles.content_container} ${commonStyles.container}`}>
              <img className={commonStyles.any_rules} src={cdnLoader('/images/rules/any-players.svg')} alt='any rules image' />
            </div>
          </div>
        </div>
        {playersNumber.map((number, index) => {
          if (number >= 5) return
          return (
            <div 
              className = {`${commonStyles.button_wrapper}`} 
              key = {`playerNumber${number}`}
              onClick = {() => {handleChoosePlayerNumber(number, currentStep + 1)}}
              >
              <div className  = {`${commonStyles.button_container} ${selectedPlayerNumber === number ? commonStyles.active : ''} ${commonStyles.players_number}`}>
                <div className = {`${commonStyles.standart_background} ${commonStyles.container}`}></div>
                <div className = {`${commonStyles.hover_background} ${commonStyles.container}`}></div>
                <div className = {`${commonStyles.active_background} ${commonStyles.container}`}></div>
                <div className = {`${commonStyles.content_container} ${commonStyles.container}`}>
                  <h5 className = {commonStyles.number_container}>{number}</h5>
                  <div className = {commonStyles.searching_progress_players}>
                    <div className = {commonStyles.icon_container}>
                      <img src = {cdnLoader('/images/common/players-icon.svg')} alt='searching icon' />
                    </div>
                    <div className = {`${commonStyles.numbers_container} ${currentlySearchingPLayersNumbers[index] == 0 ? commonStyles.disabled: ''}`}>
                     <span className = {`${commonStyles.player_number} ${commonStyles.current_number}`}>{currentlySearchingPLayersNumbers[index]}</span>
                     {/* <span>/</span>
                     <span className = {`${commonStyles.player_number} ${commonStyles.total_number}`}>{number}</span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default PlayersNumberStep