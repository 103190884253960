import * as styles from './styles.module.scss'
import useTranslation from 'next-translate/useTranslation'
import MaintenanceIllustration from '../../public/images/maintenance-mode.svg'
import { CloseButton } from '../Buttons/Buttons'
import { cdnLoader } from '../../config/cdnImageLoader.config';
export const MaintenanceModeModal = ({
  modalType = 'classic',
  clsoeButtonFunction = () => {},
  ctaAction = () => {}
}) => {

  const {t} = useTranslation()

  const getTitle = () => {
    switch(modalType) {
      case 'classic' : return t('wizard:online_game.title');
      case 'any': return t('common:buttons.quick_play')
    }
  }

  const minimizeHandler = (e, fromButton = false) => {
    e.stopPropagation()
    // if (modalIsMinimized && !fromButton) {
    //   return
    // }
    // dispatch(toggleModalSize(!modalIsMinimized));
  }

  return (
    <div 
      onClick={(e) => {minimizeHandler(e, false)}} 
      className = {`${styles.modal_container} ${styles.zIndexHigh}`}
      >
      <div className = {styles.upper_part}>
        <div className = {styles.modal_head}>
          <div className = {styles.modal_details_container}>
            <div className = {`${styles.modal_image_container} ${styles[modalType]}`}>
              <img className = {styles.modal_image} src = {cdnLoader(`/images/dashboard/${modalType}_bottom.svg`)} />
            </div>
            <div className = {styles.modal_title_container}>
              <h2 className = {styles.modal_title}>{getTitle()}</h2>
            </div>
          </div>
            <div className = {styles.modal_close_button_container}>
              <CloseButton click = {clsoeButtonFunction} />
            </div>
        </div>
        <div className = {styles.dynamic_part}>
          <div className={styles.info_container}>
            <div className={styles.illustration_container}>
              <MaintenanceIllustration />
            </div>
            <div className={styles.text_container}>
              <h3 className={styles.info_title}>{t('common:modals.maintenance.title')}</h3>
              <p className={styles.info_text}>{t('common:modals.maintenance.text')}</p>
            </div>
          </div>
        </div>
      </div>
      <div className = {`${styles.bottom_part}`}>
        <div className = {styles.left_side}></div>
        <div className = {styles.center}>
          <button 
            className={styles.action_button}
            onClick = {ctaAction}
            >
              {t('common:modals.maintenance.button_text')}
          </button>
        </div>
        <div className = {styles.right_side}>
        </div>
      </div>
    </div>
  )
}